<template>
  <div id="news-best">
    <div class="news-banner">
      <div class="news-title">{{ $t('news-best.title') }}</div>
      <div class="news-slogan">{{ $t('news-best.slogan') }}</div>
    </div>
    <div class="news-content">
      <section class="new-list-section">
        <ul class="news-list">
          <li v-for="(best, index) in bestList" v-if="index < 10" :key="index" class="news-item">
            <figure class="news-figure">
              <div class="news-video-wrap">
                <div class="news-video" v-html="best.Iframe" />
              </div>
              <div class="news-figure-info">
                <div class="news-figure-title">{{ best.Title }}</div>
                <div class="news-figure-time">{{ best.CreatedTimeStr }}</div>
              </div>
            </figure>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
  import { getSportVideoApi } from '@/api/sport';

  export default {
    data() {
      return {
        bestList: [],
        currentBest: null,
      };
    },
    methods: {
      showBest(best) {
        this.currentBest = best;
      },
      hideBest() {
        this.currentBest = null;
      },
    },
    beforeCreate() {
      getSportVideoApi().then((response) => {
        this.bestList = response.data;
      });
    },
  };
</script>

<style lang="scss">
  @import './news.scss';
</style>

<style lang="scss" scoped>
  #news-best {
    width: 100%;
  }

  .news-item {
    //height: 260px !important;
  }

  .news-video {
    ::v-deep iframe {
      width: 100% !important;
      height: auto !important;
    }
  }
</style>
